import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { CreditBalanceComponent } from 'src/components/credit-balance/credit-balance.component'
import { ModalModule } from 'src/shared/modal'
import { CompanyCreditFormComponent } from './company-credit-form.component'
import { CompanyCreditFormService } from './company-credit-form.service'

@NgModule({
  declarations: [CompanyCreditFormComponent],
  providers: [CompanyCreditFormService],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressBarModule,
    ModalModule,
    CreditBalanceComponent,
  ],
})
export class CompanyCreditFormModule { }
