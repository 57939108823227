<table mat-table
  fixedLayout [dataSource]="dataSource"
  matSort matSortStart="desc" matSortDisableClear>

  <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayColumns" [class.tc-loading-row]="!row" [class.clickable]="!!row && isClickable"></tr>
  <tr mat-footer-row *matFooterRowDef="displayColumns" class="tc-show-footer"></tr>


  <ng-container matColumnDef="packagesCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="No. of pkg.">No. of pkg.</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditPackagesCount"
      (dblclick)="row.canEditPackagesCount && editPackagesCount(row, td)">
      <div class="text-ellipsis" [title]="row.packagesCount">{{row.packagesCount}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalPackagesCount$ | async">{{ totalPackagesCount$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Name">Name</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
      <div class="text-ellipsis" [title]="row.name">{{row.name}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="estSupplierWeight">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Estimated Buy weight">Est. Buy weight</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditEstSupplierWeight"
      (dblclick)="row.canEditEstSupplierWeight && editEstSupplierWeight(row, td)">
      <div class="text-ellipsis" [title]="row.estSupplierWeight">{{row.estSupplierWeight}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalEstSupplierWeight$ | async">{{ totalEstSupplierWeight$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="estSupplierPrice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Estimated Buy Price">Est. Buy Price</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditSupplierPrice"
      (dblclick)="row.canEditSupplierPrice && editEstSupplierPrice(row, td)">
      <div class="text-ellipsis" [title]="row.estSupplierPrice">{{row.estSupplierPrice}}</div>
      <small class="text-gray">{{row.supplierCurrencyCode}} / {{row.supplierMeasureSymbol}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="actSupplierWeight">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Actual Buy weight">Act. Buy weight</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditActSupplierWeight"
      (dblclick)="row.canEditActSupplierWeight && editActSupplierWeight(row, td)">
      <div class="text-ellipsis" [title]="row.actSupplierWeight">{{row.actSupplierWeight}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalActSupplierWeight$ | async">{{ totalActSupplierWeight$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actSupplierPrice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Actual Buy Price">Act. Buy Price</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditActSupplierPrice"
      (dblclick)="row.canEditActSupplierPrice && editActSupplierPrice(row, td)">
      <div class="text-ellipsis" [title]="row.actSupplierPrice">{{row.actSupplierPrice}}</div>
      <small class="text-gray">{{row.supplierCurrencyCode}} / {{row.supplierMeasureSymbol}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="estBuyerWeight">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Estimated Sell weight">Est. Sell weight</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditEstBuyerWeight"
      (dblclick)="row.canEditEstBuyerWeight && editEstBuyerWeight(row, td)">
      <div class="text-ellipsis" [title]="row.estBuyerWeight">{{row.estBuyerWeight}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalEstBuyerWeight$ | async">{{ totalEstBuyerWeight$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="estBuyerPrice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Estimated Sell Price">Est. Sell Price</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditBuyerPrice"
      (dblclick)="row.canEditBuyerPrice && editEstBuyerPrice(row, td)">
      <div class="text-ellipsis" [title]="row.estBuyerPrice">{{row.estBuyerPrice}}</div>
      <small class="text-gray">{{row.buyerCurrencyCode}} / {{row.buyerMeasureSymbol}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="actBuyerWeight">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Actual Sell weight">Act. Sell weight</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditActBuyerWeight"
      (dblclick)="row.canEditActBuyerWeight && editActBuyerWeight(row, td)">
      <div class="text-ellipsis" [title]="row.actBuyerWeight">{{row.actBuyerWeight}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalActBuyerWeight$ | async">{{ totalActBuyerWeight$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actBuyerPrice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Actual Sell Price">Act. Sell Price</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditActBuyerPrice"
      (dblclick)="row.canEditActBuyerPrice && editActBuyerPrice(row, td)">
      <div class="text-ellipsis" [title]="row.actBuyerPrice">{{row.actBuyerPrice}}</div>
      <small class="text-gray">{{row.buyerCurrencyCode}} / {{row.buyerMeasureSymbol}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="grossWeight">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Gross weight">Gross weight</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" #td
      [class.tc-cell-editable]="row.canEditGrossWeight"
      (dblclick)="row.canEditGrossWeight && editGrossWeight(row, td)">
      <div class="text-ellipsis" [title]="row.grossWeight">{{row.grossWeight}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalGrossWeight$ | async">{{ totalGrossWeight$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="estCost">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Estimated Cost">Est. Cost</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
      <div class="text-ellipsis" [title]="row.estCost">{{row.estCost}}</div>
      <small class="text-gray">{{row.supplierCurrencyCode}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalEstCost$ | async">{{ totalEstCost$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="partialCost">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Partial Cost">Partial Cost</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
      <div class="text-ellipsis" [title]="row.partialCost">{{row.partialCost}}</div>
      <small class="text-gray">{{row.supplierCurrencyCode}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalPartialCost$ | async">{{ totalPartialCost$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actCost">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Actual Cost">Act. Cost</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
      <div class="text-ellipsis" [title]="row.actCost">{{row.actCost}}</div>
      <small class="text-gray">{{row.supplierCurrencyCode}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalActCost$ | async">{{ totalActCost$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="estRevenue">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Estimated Revenue">Est. Revenue</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
      <div class="text-ellipsis" [title]="row.estRevenue">{{row.estRevenue}}</div>
      <small class="text-gray">{{row.buyerCurrencyCode}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalEstRevenue$ | async">{{ totalEstRevenue$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="actRevenue">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Actual Revenue">Act. Revenue</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
      <div class="text-ellipsis" [title]="row.actRevenue">{{row.actRevenue}}</div>
      <small class="text-gray">{{row.buyerCurrencyCode}}</small>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <div class="text-ellipsis" [title]="totalActRevenue$ | async">{{ totalActRevenue$ | async}}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="margin">
    <th mat-header-cell *matHeaderCellDef mat-sort-header title="Estimated Product Margin">Est. Margin</th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource" (click)="row && rowClick.emit({row: row})">
      <div class="text-ellipsis" [title]="row.margin">{{row.margin}}</div>
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <ng-container matColumnDef="ellipsisMenu">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let row; dataSource:dataSource"
      (click)="row && rowClick.emit({row: row})">
      @if (!row) {
        <div class="skeleton-loader circle"></div>
      }
      @if (row) {
        <button mat-icon-button type="button"
          [matMenuTriggerFor]="ellipsisMenu"
          [matMenuTriggerData]="{row: row}">
          <mat-icon class="fa fa-2x fa-ellipsis-v"></mat-icon>
        </button>
      }
    </td>
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>
</table>

<mat-paginator #paginator></mat-paginator>

<mat-menu #ellipsisMenu="matMenu" yPosition="below">
  <ng-template matMenuContent let-row="row">
    <button mat-menu-item type="button" (click)="showProductDetails(row)">
      <i class="fa fa-fw fa-chevron-circle-right"></i> Product Details
    </button>
    <button mat-menu-item type="button" (click)="showDealProductNotesOverlay(row)">
      <i class="fa fa-fw fa-note-sticky"></i> Notes
    </button>
    @if (canActualize(row)) {
      <button mat-menu-item (click)="showActualizeToZero(row)">
        <i class="fa fa-fw fa-money-bill-1" aria-hidden="true"></i> Actualize to $0
      </button>
    }
    @if (canUnactualize(row)) {
      <button mat-menu-item (click)="showUnactualizeToZero(row)">
        <i class="fa fa-fw fa-undo" aria-hidden="true"></i> Unactualize cost
      </button>
    }
    @if (enableAutomateAdditionalCharge) {
      <button mat-menu-item (click)="showAdditionalCost(row)">
        <i class="fa fa-fw fa-usd" aria-hidden="true"></i> Additional Charge
      </button>
    }
  </ng-template>
</mat-menu>

<tc-inline-editor #inlineEditor></tc-inline-editor>
