import { ApplicationRef, ErrorHandler, NgModule, isDevMode, DoBootstrap } from '@angular/core'
import { MAT_CHECKBOX_DEFAULT_OPTIONS, type MatCheckboxDefaultOptions } from '@angular/material/checkbox'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, type MatFormFieldDefaultOptions } from '@angular/material/form-field'
import { MAT_RADIO_DEFAULT_OPTIONS, type MatRadioDefaultOptions } from '@angular/material/radio'
import { MAT_TABS_CONFIG, type MatTabsConfig } from '@angular/material/tabs'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule } from '@angular/service-worker'
import { UpgradeModule } from '@angular/upgrade/static'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule as NgrxStoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { LoadingBarModule } from '@ngx-loading-bar/core'
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client'
import * as Sentry from '@sentry/angular'
import { HeaderModule } from 'src/components/header/header.module'
import { environment } from 'src/environments/environment'
import { DataModule } from 'src/services/data/data.module'
import { IdleTimeoutModule } from 'src/shared/idle-timeout/idle-timeout.module'
import { ToasterModule } from 'src/shared/toaster/toaster.module'
import { LazyLoaderService } from './angularjs/angularjs-loader.service'
import { LocationShimModuleProviders } from './angularjs/location-shim.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { metaReducers, reducers } from './store/reducers'
import { StoreModule } from './store/store.module'

if (environment.sentryDsn) {
  Sentry.init({
    dsn: environment.sentryDsn,
    environment: environment.env,
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        mutationLimit: 15000
      }),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 1.0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

const StoreDevtoolsModules = environment.enableStoreDevtools ? [StoreDevtoolsModule.instrument({connectInZone: true})] : []
const SentryProviders = environment.sentryDsn ? [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
        logErrors: true,
      }),
    },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => {},
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ] : []

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    UpgradeModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    AppRoutingModule,
    DataModule,
    ToasterModule,
    HeaderModule,
    BrowserAnimationsModule,
    NgrxStoreModule.forRoot(reducers, { metaReducers }),
    StoreModule,
    IdleTimeoutModule,
    ...StoreDevtoolsModules,

    EffectsModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
       enabled: !isDevMode(),
       // Register the ServiceWorker as soon as the application is stable
       // or after 30 seconds (whichever comes first).
       registrationStrategy: 'registerWhenStable:30000',
     }),
  ],
  providers: [
    ...SentryProviders,
    ...LocationShimModuleProviders,
   { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } satisfies MatFormFieldDefaultOptions },
   { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: 'primary' } satisfies MatRadioDefaultOptions },
   { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: 'primary' } satisfies MatCheckboxDefaultOptions },
   { provide: MAT_TABS_CONFIG, useValue: { stretchTabs: false } satisfies MatTabsConfig},
  ],
})
export class AppModule implements DoBootstrap {
  constructor(
    private lazyLoader: LazyLoaderService,
  ) {}

  ngDoBootstrap(appRef: ApplicationRef) {
    appRef.bootstrap(AppComponent)
    // app()
    // this.upgrade.bootstrap(document.body, ['tradecafe.webapp'], { strictDi: true })
    console.log('loading ng1 app')
    this.lazyLoader.load(document.body)
  }
}
